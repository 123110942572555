import * as React from 'react';

// components
import { Link as LinkComponent } from 'gatsby';
import RelatedArticleCard from '../components/related-article-card';
import Layout from '../components/layout';
import { CalculatorSidebar } from '../components/calculator-sidebar';
import { Article, clsx, HeadTitle } from '@monemprunt/design-system';
import CtaImage from '../components/cta-image';
import Seo from '../components/seo';

// utils
import { graphql } from 'gatsby';

// types & interfaces
import type { PageProps } from 'gatsby';
import type { CalculatorType, SEO, RelatedArticle } from '../lib/types';
import type { LogementType, UsageType } from '../lib/constants';
import { CalculatriceCapacite } from '../components/calculatrice-capacite-emprunt';
import { CalculatriceFraisDeNotaire } from '../components/calculatrice-frais-de-notaire';
import { CalculatricePTZ } from '../components/calculatrice-ptz';
import { CalculatriceMensualites } from '../components/calculatrice-mensualites';

export interface LandingPageCalculetteProps {
    id: number;
    SEO: SEO;
    pageContent: {
        article: string;
        title: string;
    };
    calculatorType: Exclude<CalculatorType, 'meteo'>;
    calculatorDefaultFields: CalculatorDefaultFields;
}

type CalculatorDefaultFields = {
    nombre_personnes_au_foyer: number;
    duree_emprunt: number;
    montant_charges: number;
    revenus_annuels: number;
    apport_personnel: number;
    revenu_fiscal_reference: number;
    primo_accedant: boolean;
    code_postal_ville: string;
    montant_bien: number;
    usage: UsageType;
    type_projet: LogementType;
    taux_assurance: number;
    taux_interet: number;
};

export default function LandingCalculettePage({
    data: {
        page: {
            SEO,
            pageContent,
            calculatorType,
            calculatorDefaultFields: defaultParams
        }
    },
    location: { pathname: path },
    pageContext: { relatedArticles }
}: PageProps<
    {
        page: LandingPageCalculetteProps;
    },
    {
        relatedArticles: Array<RelatedArticle>;
    }
>) {
    const [isSidebarOpen, setSidebarOpen] = React.useState(false);

    return (
        <Layout active="simulation-emprunt-immobilier">
            <Seo {...SEO} pathname={path} />
            <main className="mt-25 sm:mt-32 md:mt-20 mb-10">
                {/* Petit blur en arrière-plan*/}
                <div
                    className="hidden lg:block fixed top-72 h-60 2xl:top-80 2xl:h-80 left-0 right-0"
                    style={{ zIndex: -1 }}>
                    <div className="bg-gradient-to-t from-light-50 to-transparent h-full" />
                    <div className="bg-gradient-to-b from-light-50 to-transparent h-full" />
                </div>

                <div
                    className={
                        'grid grid-cols-10 lg:grid-cols-12 xl:grid-cols-12'
                    }>
                    {/* SideBar */}
                    <CalculatorSidebar
                        active={calculatorType}
                        isOpen={isSidebarOpen}
                        onToggle={setSidebarOpen}
                    />

                    {/* Content */}
                    <div
                        className={clsx(
                            `col-span-8 px-5 pt-14 flex-col gap-5 mb-32`,
                            `md:pt-15`,
                            `lg:pt-20 lg:col-span-9 lg:flex lg:px-15`,
                            `xl:col-span-9`,
                            {
                                hidden: isSidebarOpen,
                                flex: !isSidebarOpen
                            }
                        )}>
                        <HeadTitle
                            as="h1"
                            label={pageContent.title}
                            breakWords={false}
                            className="lg:text-2xl font-bold uppercase italic"
                        />

                        {/* Form */}
                        {calculatorType === 'frais_notaire' && (
                            <CalculatriceFraisDeNotaire
                                defaultParams={{
                                    prix_logement: defaultParams.montant_bien,
                                    type_projet:
                                        defaultParams.type_projet !==
                                        'RACHAT_LOGEMENT_SOCIAL'
                                            ? defaultParams.type_projet
                                            : undefined
                                }}
                            />
                        )}
                        {calculatorType === 'capacite_emprunt' && (
                            <CalculatriceCapacite
                                defaultParams={{
                                    charges_mensuelles:
                                        defaultParams.montant_charges,
                                    duree_emprunt: defaultParams.duree_emprunt,
                                    revenu_annuels:
                                        defaultParams.revenus_annuels,
                                    nombre_personnes_foyer:
                                        defaultParams.nombre_personnes_au_foyer
                                }}
                            />
                        )}
                        {calculatorType === 'ptz' && (
                            <CalculatricePTZ
                                defaultParams={{
                                    apport: defaultParams.apport_personnel,
                                    nombre_personnes_foyer:
                                        defaultParams.nombre_personnes_au_foyer,
                                    primo_accedant:
                                        defaultParams.primo_accedant,
                                    revenu_fiscal_reference:
                                        defaultParams.revenu_fiscal_reference,
                                    type_projet: defaultParams.type_projet,
                                    usage: defaultParams.usage,
                                    codePostal: defaultParams.code_postal_ville
                                }}
                            />
                        )}
                        {calculatorType === 'mensualite' && (
                            <CalculatriceMensualites
                                defaultParams={{
                                    apport: defaultParams.apport_personnel,
                                    nombre_personnes_foyer:
                                        defaultParams.nombre_personnes_au_foyer,
                                    primo_accedant:
                                        defaultParams.primo_accedant,
                                    revenu_fiscal_reference:
                                        defaultParams.revenu_fiscal_reference,
                                    type_projet: defaultParams.type_projet,
                                    codePostal: defaultParams.code_postal_ville,
                                    taux_assurance:
                                        defaultParams.taux_assurance,
                                    taux_interet: defaultParams.taux_interet,
                                    duree_emprunt: defaultParams.duree_emprunt,
                                    montant_achat: defaultParams.montant_bien
                                }}
                            />
                        )}

                        {/* Article */}
                        <Article
                            content={pageContent.article}
                            className="mt-12"
                            customLink={(props) => <LinkComponent {...props} />}
                            callToAction={() => <CtaImage />}
                        />

                        <RelatedArticles articles={relatedArticles} />
                    </div>
                </div>
            </main>
        </Layout>
    );
}

const RelatedArticles: React.FC<{
    articles: Array<RelatedArticle>;
}> = ({ articles }) =>
    articles.length > 0 && (
        <section className={'space-y-4'}>
            <h2 className="font-bold text-2xl">
                Ces articles pourraient vous intéresser :
            </h2>
            <ul className={'grid gap-6 md:grid-cols-2 lg:grid-cols-3'}>
                {articles.map((article) => (
                    <li key={article.id}>
                        <RelatedArticleCard article={article} />
                    </li>
                ))}
            </ul>
        </section>
    );

export const query = graphql`
    query singleLandingPageCalculette($id: Int) {
        page: strapiLandingPageCalculettes(strapiId: { eq: $id }) {
            id: strapiId
            pageContent {
                title
                article
            }
            SEO {
                slug
                title
                description
            }
            calculatorType
            calculatorDefaultFields {
                nombre_personnes_au_foyer
                duree_emprunt
                montant_charges
                revenus_annuels
                apport_personnel
                revenu_fiscal_reference
                primo_accedant
                code_postal_ville
                montant_bien
                usage
                type_projet
                taux_assurance
                taux_interet
            }
        }
    }
`;
