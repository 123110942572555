import * as React from 'react';

// components
import {
    Card,
    CtaButton,
    HeadTitle,
    Loader,
    NumberInput,
    Range
} from '@monemprunt/design-system';

// utils
import { clsx } from '@monemprunt/design-system';
import {
    empty,
    formatNumberToDuration,
    formatNumberToEuros
} from '../lib/functions';
import ThinkingImage from '../../src/images/question.png';
import { useCapaciteEmpruntQuery } from '../lib/use-capacite-emprunt-query';

// types & interfaces
export type CalculatriceCapaciteProps = {
    defaultParams?: Parameters<typeof useCapaciteEmpruntQuery>[0];
};

export function CalculatriceCapacite({
    defaultParams
}: CalculatriceCapaciteProps) {
    const { onChange, request, response } = useCapaciteEmpruntQuery(
        defaultParams
    );

    const {
        charges_mensuelles,
        duree_emprunt,
        nombre_personnes_foyer,
        revenu_annuels
    } = request;

    const { data, errors, isLoading } = response;

    return (
        <div className="grid md:grid-cols-8 gap-5 lg:gap-14">
            <form
                className="col-span-4 flex flex-col gap-5 md:gap-12 py-4 max-w-full"
                onSubmit={(e) => e.preventDefault()}>
                <NumberInput
                    label="NOMBRE DE PERSONNES AU FOYER"
                    showArrows
                    size="small"
                    onChange={(newValue) =>
                        onChange({
                            key: 'nombre_personnes_foyer',
                            value: newValue
                        })
                    }
                    value={nombre_personnes_foyer}
                    errorText={errors?.nombre_personnes_foyer}
                />
                <NumberInput
                    label="VOS REVENUS ANNUELS"
                    autoFocus
                    onChange={(newValue) =>
                        onChange({ key: 'revenu_annuels', value: newValue })
                    }
                    value={revenu_annuels}
                    appendix="€"
                    hintText="Total de vos revenus annuels nets avant impôts"
                    errorText={errors?.revenu_annuels}
                />

                <Range
                    label="DURÉE DU PRÊT"
                    min={5}
                    max={25}
                    value={duree_emprunt}
                    appendix="ans"
                    inputSize="small"
                    step={1}
                    fieldEditable={false}
                    hintText="Entre 5 et 25 ans"
                    onChange={(newValue) =>
                        onChange({ key: 'duree_emprunt', value: newValue })
                    }
                    className="mb-4"
                    errorText={errors?.duree_emprunt}
                />

                <NumberInput
                    label="MONTANT DES CHARGES APRES PROJET"
                    onChange={(newValue) =>
                        onChange({
                            key: 'charges_mensuelles',
                            value: newValue
                        })
                    }
                    value={charges_mensuelles}
                    appendix="€/mois"
                    errorText={errors?.charges_mensuelles}
                    hintText="Ce montant peut comprendre les charges suivantes : <br/> Crédit, Loyer, leasing, pension alimentaire…"
                    size="small"
                />

                <div
                    className={clsx(`flex-col gap-4 hidden md:flex`, {
                        '!hidden': data && !!data.message
                    })}>
                    <p className="italic">
                        <a
                            href="/tunnelmobile/"
                            className="underline text-secondary-100">
                            Estimez plus précisément
                        </a>
                        &nbsp; votre projet immobilier{' '}
                        <strong className="font-bold">gratuitement</strong> en 3
                        minutes et obtenez 3 propositions de prêt.
                    </p>

                    <CtaButton
                        link="/tunnelmobile/"
                        text="Simulation avancée"
                        className="self-start"
                    />
                </div>
            </form>
            <div className="col-span-4 flex flex-col gap-10 h-full">
                <Card className="flex flex-col gap-4 bg-white items-center justify-center text-center">
                    {isLoading ? (
                        <>
                            <Loader type="stairs" />
                            <p className="text-center">Calcul en cours...</p>
                        </>
                    ) : !empty(errors) ? (
                        <>
                            <p className="text-2xl text-center font-bold">
                                Veuillez corriger les erreurs sur le formulaire.
                            </p>
                        </>
                    ) : data !== null ? (
                        <>
                            {data.message ? (
                                <div className="flex flex-col gap-4 text-base text-left">
                                    <p className="text-3xl flex justify-center my-4">
                                        <img
                                            src={ThinkingImage}
                                            className="h-30 filter"
                                            style={{
                                                filter: `invert(17%) sepia(21%) saturate(2353%) hue-rotate(148deg) brightness(96%) contrast(103%)`
                                            }}
                                            alt="Pensif"
                                        />
                                    </p>

                                    <div className="text-base">
                                        {data.message}
                                    </div>

                                    <p>
                                        Mais il y a peut-être des
                                        solutions&nbsp;
                                        <span
                                            role="img"
                                            aria-label="Emoji clin d'oeil">
                                            😉
                                        </span>
                                        &nbsp;, effectuez gratuitement une&nbsp;
                                        <a
                                            href="/tunnelmobile/"
                                            className="text-secondary-100 underline">
                                            simulation personnalisée
                                        </a>
                                        &nbsp; pour un résultat plus précis.
                                    </p>

                                    <CtaButton
                                        link="/tunnelmobile/"
                                        text="J'estime mon prêt"
                                        className="self-start"
                                    />
                                </div>
                            ) : (
                                <>
                                    <p className="text-lg text-center font-bold italic md:text-xl">
                                        VOTRE CAPACITÉ D'EMPRUNT :
                                    </p>

                                    {data.capacite_par_duree
                                        .sort((a, b) => {
                                            if (
                                                a.duree_pret_principal ===
                                                duree_emprunt * 12
                                            ) {
                                                return -1;
                                            } else if (
                                                b.duree_pret_principal ===
                                                duree_emprunt * 12
                                            ) {
                                                return 1;
                                            }
                                            return 0;
                                        })
                                        .map((item, index) => (
                                            <li
                                                key={index}
                                                className={clsx(
                                                    `flex flex-col gap-2 py-2`,
                                                    {
                                                        'border-b-2 border-gray-200':
                                                            index !==
                                                            data[
                                                                'capacite_par_duree'
                                                            ].length -
                                                                1
                                                    }
                                                )}>
                                                <HeadTitle
                                                    label={`Sur ${formatNumberToDuration(
                                                        item.duree_pret_principal
                                                    )}`}
                                                    className={clsx({
                                                        'text-lg -skew-x-6 md:text-xl':
                                                            item.duree_pret_principal ===
                                                            duree_emprunt * 12,
                                                        'text-xs md:text-sm':
                                                            item.duree_pret_principal !==
                                                            duree_emprunt * 12
                                                    })}
                                                    variant={
                                                        item.duree_pret_principal ===
                                                        duree_emprunt * 12
                                                            ? 'primary'
                                                            : 'secondary'
                                                    }
                                                />

                                                <p
                                                    className={clsx({
                                                        'font-bold text-base md:text-lg':
                                                            item.duree_pret_principal ===
                                                            duree_emprunt * 12,
                                                        'text-xs font-thin md:text-sm':
                                                            item.duree_pret_principal !==
                                                            duree_emprunt * 12
                                                    })}>
                                                    Montant max empruntable
                                                    :&nbsp;
                                                    <br />
                                                    <span className="text-secondary-100 font-bold">
                                                        {formatNumberToEuros(
                                                            item.montant_max_empruntable
                                                        )}
                                                    </span>
                                                </p>
                                            </li>
                                        ))}
                                </>
                            )}
                        </>
                    ) : (
                        <p className="text-2xl text-center font-bold my-12">
                            Veuillez remplir le formulaire pour évaluer votre
                            capacité d'emprunt.
                        </p>
                    )}
                </Card>

                <div
                    className={clsx(`flex-col gap-4 flex md:hidden`, {
                        hidden: data && !!data.message
                    })}>
                    <p className="italic">
                        <a
                            href="/tunnelmobile/"
                            className="underline text-secondary-100">
                            Estimez plus précisément
                        </a>
                        &nbsp; votre projet immobilier&nbsp;
                        <strong className="font-bold">gratuitement</strong> en 3
                        minutes et obtenez 3 propositions de prêt.
                    </p>

                    <CtaButton
                        link="/tunnelmobile/"
                        text="Simulation avancée"
                        className="self-start"
                    />
                </div>
            </div>
        </div>
    );
}
