import * as React from 'react';
import { empty, formatNumberToEuros } from '../lib/functions';
import {
    Card,
    CtaButton,
    Loader,
    NumberInput,
    Radio
} from '@monemprunt/design-system';
import { TypeOfKey } from '../lib/types';
import {
    FraisDeNotaireRequest,
    useFraisDeNotaireQuery
} from '../lib/use-frais-de-notaire-query';

export type CalculatriceFraisDeNotaireProps = {
    defaultParams?: Parameters<typeof useFraisDeNotaireQuery>[0];
};

export function CalculatriceFraisDeNotaire({
    defaultParams
}: CalculatriceFraisDeNotaireProps) {
    const {
        request: { type_projet, prix_logement },
        response,
        onChange
    } = useFraisDeNotaireQuery(defaultParams);

    const { data, isLoading, errors } = response;

    return (
        <div className="grid md:grid-cols-5 gap-5">
            <form
                className="flex flex-col gap-5 md:gap-10 py-4  md:col-span-3"
                onSubmit={(e) => e.preventDefault()}>
                <Radio<TypeOfKey<FraisDeNotaireRequest, 'type_projet'>>
                    label="PROJET"
                    onChange={(newValue) =>
                        onChange({ key: 'type_projet', value: newValue })
                    }
                    options={[
                        {
                            label: 'Neuf',
                            value: 'LOGEMENT_NEUF'
                        },
                        {
                            label: 'Ancien',
                            value: 'LOGEMENT_ANCIEN'
                        }
                    ]}
                    value={type_projet}
                />
                <NumberInput
                    appendix="€"
                    hintText="Hors frais d’agences et mobiliers"
                    label="Prix du bien"
                    autoFocus
                    onChange={(newValue) =>
                        onChange({ key: 'prix_logement', value: newValue })
                    }
                    errorText={errors?.prix_logement}
                    value={prix_logement}
                />

                <p className="hidden md:block italic">
                    <a
                        href="/tunnelmobile/"
                        className="underline text-secondary-100">
                        Estimez plus précisément
                    </a>
                    &nbsp; votre projet immobilier{' '}
                    <strong className="font-bold">gratuitement</strong> en 3
                    minutes et obtenez 3 propositions de prêt.
                </p>

                <CtaButton
                    link="/tunnelmobile/"
                    text="Simulation avancée"
                    className="!hidden md:!flex self-start"
                />
            </form>

            <Card className="flex flex-col gap-2 bg-white items-center justify-center md:col-span-2">
                {isLoading ? (
                    <>
                        <Loader type="stairs" />
                        <p className="text-center">Simulation en cours...</p>
                    </>
                ) : data && empty(errors) ? (
                    <>
                        <p className="text-2xl text-center font-bold">
                            VOS FRAIS DE NOTAIRE :
                        </p>
                        <p className="text-xl font-bold text-center text-secondary-100 italic md:text-2xl">
                            {formatNumberToEuros(data.total)}
                        </p>
                        <hr className="bg-light-100 h-[2px] w-full" />
                        <div className="w-full h-full flex flex-col justify-center gap-4">
                            <p className="underline text-sm md:text-base">
                                Détails :
                            </p>
                            <p className="italic text-sm md:text-base">
                                Droits et taxes:&nbsp;
                                <em className="text-secondary-100">
                                    {formatNumberToEuros(data.taxe_impots)}
                                </em>
                            </p>
                            <p className="italic text-sm md:text-base">
                                Emoluments:&nbsp;
                                <em className="text-secondary-100">
                                    {formatNumberToEuros(data.emolument)}
                                </em>
                            </p>
                            <p className="italic text-sm md:text-base">
                                Débours:&nbsp;
                                <em className="text-secondary-100">
                                    {formatNumberToEuros(data.debours)}
                                </em>
                            </p>
                            <p className="italic text-sm md:text-base">
                                Contribution:&nbsp;
                                <em className="text-secondary-100">
                                    {formatNumberToEuros(data.securit_immo)}
                                </em>
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="text-2xl text-center font-bold">
                            Veuillez Corriger les erreurs sur le formulaire.
                        </p>
                    </>
                )}
            </Card>

            <div className="flex flex-col gap-4">
                <p className="italic text-sm md:text-base md:hidden">
                    <a
                        href="/tunnelmobile/"
                        className="underline text-secondary-100">
                        Estimez plus précisément
                    </a>
                    &nbsp;votre projet immobilier&nbsp;
                    <strong className="font-bold">gratuitement</strong> en 3
                    minutes et obtenez 3 propositions de prêt.
                </p>

                <CtaButton
                    link="/tunnelmobile/"
                    text="Simulation avancée"
                    className="md:!hidden self-start"
                />
            </div>
        </div>
    );
}
