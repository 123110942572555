import * as React from 'react';
import { LogementType } from './constants';
import { ApiResponse, FormErrors, FormResult, Unionize } from './types';
import {
    getParameterInViolationPath,
    isCalculetteResponseSuccessful,
    jsonFetch,
    wait
} from './functions';
import { useQuery } from '@tanstack/react-query';

// seulement les 2 types de logement sont supportés
type SimpleProjectType = Extract<
    LogementType,
    'LOGEMENT_ANCIEN' | 'LOGEMENT_NEUF'
>;

export type FraisDeNotaireRequest = {
    type_projet: SimpleProjectType;
    prix_logement: number;
};

export type FraisDeNotaireResponse = {
    taxe_impots: number;
    total: number;
    securit_immo: number;
    debours: number;
    emolument: number;
};

export function useFraisDeNotaireQuery(
    defaultParameters: Partial<FraisDeNotaireRequest> = {}
) {
    // requête
    const [projectType, setProjectType] = React.useState<SimpleProjectType>(
        defaultParameters.type_projet ?? 'LOGEMENT_NEUF'
    );
    const [acquisitionPrice, setAcquisitionPrice] = React.useState(
        defaultParameters.prix_logement ?? 210_000
    );

    // Fonction pour changer les paramètres de la requête
    const handleChange = (keyValuePair: Unionize<FraisDeNotaireRequest>) => {
        switch (keyValuePair.key) {
            case 'type_projet':
                setProjectType(() => keyValuePair.value);
                break;
            case 'prix_logement':
                setAcquisitionPrice(() => keyValuePair.value);
                break;
        }
    };

    const { data: response, error, isInitialLoading } = useQuery({
        queryKey: ['FRAIS_DE_NOTAIRE', { projectType, acquisitionPrice }],
        queryFn: async ({ signal }) => {
            // simuler un debounce naif
            await wait(1500);
            if (signal.aborted) {
                return;
            }

            const response = await jsonFetch<
                ApiResponse<FraisDeNotaireResponse>
            >(
                `${process.env.GATSBY_CALCULETTE_API_URL}/notaire`,
                {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        cle: process.env.GATSBY_CALCULETTE_API_CLE
                    },
                    body: JSON.stringify({
                        type_projet: projectType,
                        prix_logement: acquisitionPrice * 100
                    })
                },
                signal
            );

            if (isCalculetteResponseSuccessful(response)) {
                return response;
            } else {
                // formatter correctement les erreurs
                let errors: FormErrors<FraisDeNotaireRequest> = {};
                for (const { message, path } of response.parameterViolations) {
                    errors = {
                        ...errors,
                        [getParameterInViolationPath(path)]: message
                    };
                }

                throw errors;
            }
        }
    });

    return {
        request: {
            type_projet: projectType,
            prix_logement: acquisitionPrice
        },
        response: {
            isLoading: isInitialLoading,
            data: response,
            errors: error as FormErrors<FraisDeNotaireRequest> | undefined
        },
        onChange: handleChange
    };
}
