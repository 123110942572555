import * as React from 'react';
import { LogementType, UsageType } from './constants';

import {
    getDurationValuesAround,
    getParameterInViolationPath,
    isCalculetteResponseSuccessful,
    jsonFetch,
    wait
} from './functions';
import { useQuery } from '@tanstack/react-query';

import type { ApiResponse, FormErrors, GrilleTaux, Unionize } from './types';

type CapaciteEmpruntRequestFixedValues = {
    // valeurs fixées pour le front,
    // L'API a besoin de ces valeurs, mais elles ne figurent pas dans le formulaire
    // Du coup on fixe les valeurs pour l'API
    zone: 'A';
    usage: Extract<UsageType, 'RES_PRINCIPALE'>;
    primo_accedant: false;
    type_projet: Extract<LogementType, 'LOGEMENT_NEUF'>;
    apport: 0;
    revenu_fiscal_reference: 0;
    avec_mentions_legales: false;
};

export type CapaciteEmpruntRequest = {
    // valeur non affichée dans le formulaire,
    // mais à déduire automatiquement en fonction de la durée de l'emprunt
    taux_par_duree?: GrilleTaux;

    // valeurs affichées dans le formulaire
    nombre_personnes_foyer: number;
    revenu_annuels: number;
    charges_mensuelles: number;

    // non utilisé par l'API, mais affiché dans le formulaire
    duree_emprunt: number;
};

export type CapaciteEmpruntResponse = {
    capacite_par_duree: {
        montant_max_empruntable: number;
        duree_pret_principal: number;
    }[];
    message?: React.ReactNode;
};

export function useCapaciteEmpruntQuery(
    defaultParameters: Partial<
        Omit<CapaciteEmpruntRequest, 'taux_par_duree'>
    > = {}
) {
    // -- REQUETE
    // affiché dans le formulaire
    const grilleTauxInteret = React.useRef<GrilleTaux>([]);
    const [nombre_personnes_foyer, setNombrePersonnesFoyer] = React.useState(
        defaultParameters.nombre_personnes_foyer ?? 1
    );
    const [duree_emprunt, setDureeEmprunt] = React.useState(
        defaultParameters.duree_emprunt ?? 25
    );
    const [revenu_annuels, setRevenuAnnuels] = React.useState(
        defaultParameters.revenu_annuels ?? 0
    );
    const [charges_mensuelles, setChargesMensuelles] = React.useState(
        defaultParameters.charges_mensuelles ?? 0
    );

    // construire la requête à envoyer à l'API
    const request: CapaciteEmpruntRequest &
        CapaciteEmpruntRequestFixedValues = {
        zone: 'A',
        usage: 'RES_PRINCIPALE',
        primo_accedant: false,
        type_projet: 'LOGEMENT_NEUF',
        apport: 0,
        revenu_fiscal_reference: 0,

        // valeurs affichées dans le formulaire
        nombre_personnes_foyer,
        revenu_annuels,
        charges_mensuelles,
        duree_emprunt,
        avec_mentions_legales: false
    };

    // Fonction pour changer les paramètres de la requête
    const handleChange = (keyValuePair: Unionize<CapaciteEmpruntRequest>) => {
        switch (keyValuePair?.key) {
            case 'nombre_personnes_foyer':
                setNombrePersonnesFoyer(keyValuePair.value);
                break;
            case 'revenu_annuels':
                setRevenuAnnuels(keyValuePair.value);
                break;
            case 'charges_mensuelles':
                setChargesMensuelles(keyValuePair.value);
                break;
            case 'duree_emprunt':
                setDureeEmprunt(keyValuePair.value);
                break;
        }
    };

    // Fonction pour lancer la requête
    const {
        data: response,
        error,
        isInitialLoading
    } = useQuery<CapaciteEmpruntResponse>({
        enabled: revenu_annuels !== 0,
        queryKey: ['CAPACITE_EMPRUNT', request],
        queryFn: async ({ signal }) => {
            // récupérer les valeurs de la requête à envoyer
            const { duree_emprunt, ...requestToSend } = request;

            // la durée de l'emprunt doit être comprise entre 5 et 25 ans
            if (duree_emprunt < 5 || duree_emprunt > 25) {
                throw {
                    duree_emprunt:
                        "La durée de l'emprunt doit être comprise entre 5 et 25 ans."
                };
            }

            // Charger la grille des taux d'intérêt si elle n'est pas déjà chargée
            if (grilleTauxInteret.current.length === 0) {
                const data = await jsonFetch<GrilleTaux>(
                    `${process.env.GATSBY_CALCULETTE_API_URL}/taux`
                );

                grilleTauxInteret.current = data.map(
                    ({ assurance, duree, interet }) => ({
                        assurance: assurance,
                        interet: interet,
                        duree: duree / 12 // diviser par 12, car la durée affichée sur l'ui est en années
                    })
                );
            }

            // trouver les paliers de taux d'intérêt correspondant à la durée de l'emprunt
            const levels = getDurationValuesAround(duree_emprunt);
            requestToSend.taux_par_duree = grilleTauxInteret.current
                .filter(({ duree }) => levels.includes(duree))
                .map((taux) => ({
                    ...taux,
                    duree: taux.duree * 12 // multiplier par 12, car la durée affichée sur l'UI est en années
                }));

            // simuler un debounce naif
            await wait(1500);
            if (signal.aborted) {
                return;
            }

            const response = await jsonFetch<
                ApiResponse<CapaciteEmpruntResponse>
            >(
                `${process.env.GATSBY_CALCULETTE_API_URL}/capacite-emprunt`,
                {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        cle: process.env.GATSBY_CALCULETTE_API_CLE
                    },
                    body: JSON.stringify({
                        ...requestToSend,
                        // mutiplier tous les montants par 100 pour avoir des valeurs en centimes
                        revenu_annuels: request.revenu_annuels * 100,
                        charges_mensuelles: request.charges_mensuelles * 100
                    })
                },
                signal
            );

            if (isCalculetteResponseSuccessful(response)) {
                // Si le max empruntable est égal à 0, on affiche un message d'erreur
                if (
                    response.capacite_par_duree[0].montant_max_empruntable === 0
                ) {
                    response.message = (
                        <p>
                            Selon les{' '}
                            <strong className="font-bold">
                                informations que vous avez saisies
                            </strong>
                            , nous ne sommes pas en mesure de déterminer votre
                            capacité d'emprunt.
                        </p>
                    );
                }

                return response;
            } else {
                // formatter correctement les erreurs
                let errors: FormErrors<CapaciteEmpruntRequest> = {};

                for (const { message, path } of response.parameterViolations) {
                    let key = getParameterInViolationPath<
                        keyof CapaciteEmpruntRequest
                    >(path);
                    let messageValue = message;

                    if (key === 'taux_par_duree') {
                        key = 'duree_emprunt';
                        messageValue = 'La durée renseignée est invalide';
                    }

                    errors = {
                        ...errors,
                        [key]: messageValue
                    };
                }

                throw errors;
            }
        }
    });

    return {
        request,
        response: {
            errors: error as FormErrors<CapaciteEmpruntRequest> | undefined,
            isLoading: isInitialLoading,
            data: response ?? null
        },
        onChange: handleChange
    };
}
